import { ITEM_STATUS, ORDER_STATUS } from '@/constants'
import Presenter from '@/components/pages/user/exchangeHistory/Presenter'
import UtilMethodsMixin from '@/mixins/UtilMethodsMixin'
import store from '@/store'

export default {
  mixins: [
    UtilMethodsMixin
  ],

  data: () => ({
    histories: []
  }),

  async created () {
    var historyList = []
    const histories = await this.$store.dispatch('exchangeHistory/getList')
    for (const history of histories) {
      historyList.push(this.setOrderItemDisplayAttributes(history, false))
    }
    this.histories = historyList
  },

  render (h) {
    return h(Presenter, {
      props: {
        histories: this.histories
      },

      on: {
        async addToCart (itemUuid, count, userNote) {
          const data = {
            itemUuid: itemUuid,
            count: count,
            userNote: userNote
          }
          const cartInfo = await store.dispatch('cart/addItems', data)
          store.commit('updateCartInfo', cartInfo)
          store.commit('message/show', {
            message: 'アイテムをカートに追加しました。',
            color: 'info',
            timeout: 4000
          })
        },
        async cancel (orderNumber, itemUuid, presenter) {
          store.commit('updateLoading', { flag: true })
          const data = {
            'orderNumber': orderNumber,
            'payload': { 'itemUuid': itemUuid }
          }
          await store.dispatch('order/cancel', data)
            .then(res => {
              // update item status in ExchangeHistories
              presenter.histories.find(hst => {
                if (hst.orderNumber === orderNumber) {
                  let orderStatus = ORDER_STATUS.CLOSED
                  hst.items.find(itm => {
                    if (itm.uuid === itemUuid) {
                      itm.status = ITEM_STATUS.CANCELED
                    }
                    if (itm.status === ITEM_STATUS.IN_PROGRESS) {
                      orderStatus = ORDER_STATUS.OPEN
                    }
                  })
                  hst.status = orderStatus
                }
              })
              // update user's points
              const userInfo = store.state.userInfo
              userInfo.point = res.point
              store.commit('updateUserInfo', userInfo)
            })
          store.commit('updateLoading', { flag: false })
          store.commit('message/show', {
            message: 'キャンセルしました。',
            color: 'info',
            timeout: 4000
          })
        }
      }
    })
  }
}
