<template>
  <div class="justify-center ma-15">
    <v-card
      v-for="history in histories"
      :key="history.orderNumber"
      class="mb-10 pa-12 welfare-card inset"
    >
      <v-row>
        <!-- history information -->
        <v-card-text
          style="display: flex; justify-content: space-between;"
        >
          <v-chip
            v-if="history.status === ORDER_STATUS.OPEN"
            label
            width="100"
            color="success"
          >
            進行中アイテムあり
          </v-chip>
          <v-chip
            v-else
            label
            width="100"
            color="accent"
          >
            完了
          </v-chip>
          <div>
            注文確定日：{{ history.createdAt }}<br>
            注文番号：{{ history.orderNumber }}
          </div>
        </v-card-text>
      </v-row>
      <!-- items -->
      <v-row
        v-for="item in history.items"
        :key="item.uuid"
      >
        <v-col>
          <v-card
            class="welfare-card small-outset pa-3"
          >
            <v-row>
              <v-col cols="3">
                <v-img
                  v-if="item.mainImgSrc && !item.deletedAt"
                  :src="item.mainImgSrc"
                  height="150"
                  contain
                />
                <v-img
                  v-else
                  src="@/assets/img/no_image.png"
                  height="150"
                  contain
                />
              </v-col>
              <v-col cols="9">
                <v-col>
                  <v-card-title
                    class="d-block text-truncate"
                  >
                    <v-chip
                      v-if="item.status === ITEM_STATUS.IN_PROGRESS"
                      label
                      color="success"
                      width="100"
                      class="mr-3"
                    >
                      {{ ITEM_STATUS_LABEL[item.status] }}
                    </v-chip>
                    <v-chip
                      v-else
                      label
                      width="100"
                      class="mr-3"
                    >
                      {{ ITEM_STATUS_LABEL[item.status] }}
                    </v-chip>
                    <router-link
                      v-if="!item.deletedAt"
                      :to="{ name: 'ItemDetail', params: { uuid: item.uuid } }"
                      class="text-decoration-none"
                    >
                      {{ item.name }}
                    </router-link>
                    <div
                      v-else
                    >
                      {{ item.name }}
                    </div>
                  </v-card-title>
                </v-col>
                <v-card-text
                  style="white-space: pre-wrap;"
                >
                  単価：{{ item.price.toLocaleString() }}pt<br>
                  数量：{{ item.count }}<br>
                  有効期限：
                  <span
                    v-if="item.expirationDate != 'なし'
                      && item.status === ITEM_STATUS.IN_PROGRESS
                      && item.isExpired"
                    class="expired-text"
                  >
                    {{ item.expirationDate }}
                  </span>
                  <span
                    v-else-if="item.expirationDate"
                  >
                    {{ item.expirationDate }}
                  </span>
                  <br>
                  管理者からのメッセージ：{{ item.adminNote }}
                  <br>
                  備考：{{ item.userNote }}
                </v-card-text>
                <v-card-actions
                  style="display: flex; justify-content: space-between;"
                >
                  <item-modal-button
                    v-if="!item.deletedAt"
                    button-text="もう一度カートに入れる"
                    button-color="primary"
                    button-icon="mdi-cart-arrow-down"
                    button-width="230"
                    :button-disabled="false"
                    :item="item"
                    @ok-action="addToCart"
                  />
                  <v-card-text
                    v-else
                  >
                    現在取り扱い停止中
                  </v-card-text>
                  <confirm-button
                    v-if="allowCancel(history, item)"
                    button-text="キャンセルする"
                    button-color="error"
                    :button-outlined="true"
                    :dialog-title="getCancelConfirmMessage(item)"
                    dialog-body="よろしいですか？"
                    @ok-action="cancel(history.orderNumber, item.uuid)"
                  />
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { CANCEL_DEAD_LINE, ITEM_STATUS, ITEM_STATUS_LABEL, ORDER_STATUS } from '@/constants'
import ConfirmButton from '@/components/common/ConfirmButton'
import ItemModalButton from '@/components/common/ItemModalButton'
import UtilMethodsMixin from '@/mixins/UtilMethodsMixin'

export default {
  components: {
    ConfirmButton, ItemModalButton
  },
  mixins: [
    UtilMethodsMixin
  ],

  props: {
    histories: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ITEM_STATUS () {
      return ITEM_STATUS
    },
    ITEM_STATUS_LABEL () {
      return ITEM_STATUS_LABEL
    },
    ORDER_STATUS () {
      return ORDER_STATUS
    }
  },

  methods: {
    allowCancel (history, item) {
      // Accept cancellation before CANCEL_DEAD_LINE of the day
      const createdAt = new Date(history.createdAt)
      return (
        item.status === ITEM_STATUS.IN_PROGRESS &&
        this.isToday(createdAt) &&
        new Date().getHours() < CANCEL_DEAD_LINE
      )
    },
    getCancelConfirmMessage (item) {
      return `「${item.name}」をキャンセルします`
    },
    addToCart (item, count, userNote) {
      this.$emit('addToCart', item.uuid, count, userNote)
    },
    cancel (orderNumber, itemUuid) {
      this.$emit('cancel', orderNumber, itemUuid, this)
    }
  }
}
</script>
