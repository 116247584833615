<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        rounded
        :color="buttonColor"
        :disabled="buttonDisabled"
        :width="buttonWidth"
        class="small-outset ma-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon v-if="buttonIcon">
          {{ buttonIcon }}
        </v-icon>
        {{ buttonText }}
      </v-btn>
    </template>
    <v-card class="pa-5">
      <v-btn
        text
        @click="dialog = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title class="text-h5">
        {{ item.name }}
      </v-card-title>
      <v-img
        v-if="item.mainImgSrc"
        :src="item.mainImgSrc"
        height="250"
        class="mb-5"
        contain
      />
      <v-card-text class="pre_line">
        単価：{{ item.price }}pt<br>
        有効期限：{{ item.expirationPeriod ? `交換から ${item.expirationPeriod} 日間` : "なし" }}
      </v-card-text>
      <v-select
        v-model="count"
        outlined
        dense
        class="ml-4"
        :items="countSelectItems"
        label="数量"
        style="width: 100px;"
      />
      <v-card-text
        v-if="item.precaution"
        style="white-space: pre-wrap;"
      >
        <p class="warning--text font-weight-bold mb-0">
          <v-icon class="warning--text">
            mdi-alert
          </v-icon>
          注意事項
        </p>
        {{ item.precaution }}
      </v-card-text>
      <v-checkbox
        v-if="item.precaution"
        v-model="hasConfirmed"
        class="ml-4"
        label="上記注意事項に同意しました"
        color="info"
      />
      <v-textarea
        v-model="userNote"
        outlined
        class="ml-4"
        label="備考"
        hint="担当者への連絡事項など"
      />
      <v-card-actions>
        <v-spacer />
        <confirm-button
          v-if="item.expirationPeriod"
          button-text="カートに入れる"
          button-color="primary"
          button-icon="mdi-cart-arrow-down"
          button-width="200"
          :button-disabled="cartBtnDisabled"
          dialog-title="要確認！"
          dialog-body="このアイテムには有効期限があります。"
          @ok-action="ok"
        />
        <v-btn
          v-else
          color="primary"
          rounded
          width="200"
          :disabled="cartBtnDisabled"
          class="small-outset mb-3"
          @click="ok"
        >
          <v-icon class="mr-2">
            mdi-cart-arrow-down
          </v-icon>
          カートに入れる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ConfirmButton from '@/components/common/ConfirmButton'
import constants from '@/constants.js'

export default {
  components: {
    ConfirmButton
  },

  props: {
    buttonText: {
      type: String,
      default: ''
    },
    buttonColor: {
      type: String,
      default: ''
    },
    buttonIcon: {
      type: String,
      default: ''
    },
    buttonDisabled: {
      type: Boolean,
      default: false
    },
    buttonWidth: {
      type: String,
      default: undefined
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: () => {}
    }
  },

  data: () => ({
    dialog: false,
    count: 0,
    userNote: '',
    hasConfirmed: false
  }),

  computed: {
    cartBtnDisabled () {
      return !this.count || (this.item.precaution && !this.hasConfirmed)
    },
    countSelectItems () {
      const selectItems = []
      Array(constants.MAX_COUNT_PER_ITEM).fill().forEach((_, i) => selectItems.push(i + 1))
      return selectItems
    }
  },

  methods: {
    ok () {
      this.$emit('ok-action', this.item, this.count, this.userNote)
      this.dialog = false
    }
  }
}
</script>

<style lang="scss" scoped>
.pre_line {
  white-space: pre-line;
}
</style>
